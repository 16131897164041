import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  handleCheck(event) {
    if (event.target.checked) {
      this.element.classList.add("is-expanded");
    } else {
      this.element.classList.remove("is-expanded");
    }
  }
}
